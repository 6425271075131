'use client'
import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'

export default function GlobalError({
  error,
  reset
}: {
  error: Error
  reset: () => void
}) {
  useEffect(() => {
    console.log('capturing report dialog error', error)
    const eventId = Sentry.captureException(error)

    Sentry.showReportDialog({
      eventId,
      title: 'Oops! It looks like there has been a problem.',
      subtitle:
        "Please can you fill out the form below so our team can make sure this problem doesn't happen again? Thank you!"
    })
  }, [error])

  return (
    <div>
      <h2>Something went wrong!</h2>
      <button onClick={() => reset()}>Try again</button>
    </div>
  )
}
